// Global route list
const routeList = {
    home: "/",
    notFound: "/not-found",
    login: "/login",
    launcherSetting: "/launcher/setting",
    playerManager: "/player/manager",
    multiAccount: "/multi/account-check",
    paymentEvent: "/payment/event",
    cashAndPetal: "/cash-and-petal/top-list"
  };
  
  // Get route from list by key
  const getRoute = (key, relative = true) => {
    // Default route
    let result = "/";
  
    if (routeList[key]) {
      // Route from list
      result = routeList[key];
  
      // Remove first slash if it's not relative
      if (!relative) {
        result = result.replace("/", "");
      }
    }
  
    return result;
  };
  
  export default getRoute;
  