import _ from "lodash";
const moment = require('moment');
import 'moment-timezone';

export default {
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  getGermanyDateNow(){
    return  moment().tz('Europe/Berlin').format('YYYY-MM-DD HH:mm:ss');
  },
  parseMoney(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  },
  numberFormat(value, localeFormatter) {
    return new Intl.NumberFormat(localeFormatter).format(value);
  },
  moneyFormat(number, currencyCode, localeFormatter) {
    return new Intl.NumberFormat(localeFormatter, {
      style: "currency",
      currency: currencyCode,
    }).format(number);
  },
  randomString(length = 10) {
    let charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  },
  allCharacterIsNumeric(value) {
    return String(value)
      .split("")
      .find((A) => !/^\d+$/.test(A))
      ? false
      : true;
  },
  validateIPaddress(ipaddress) {
    if (
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
        ipaddress
      )
    ) {
      return true;
    }
    return false;
  },
  isNumeric(num) {
    if (num === "" || num === null) {
      return false;
    }
    return !isNaN(num);
  },
  getCurrencySymbol(locale, currency) {
    return (0)
      .toLocaleString(locale, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, "")
      .trim();
  },
  onlyNumber($event) {
    let keyCode = $event.keyCode ? $event.keyCode : $event.which;
    if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
      $event.preventDefault();
    }
  },
  paginateLinks(pages, currentPage) {
    var links = [];
    var approved = [];
    let center = Math.round(pages / 2) - 1;
    for (var i = 0; i < pages; i++) {
      if (pages > 6) {
        let difference = currentPage - i;
        let centerDifference = center - i;
        // around the current page
        if (!(difference < 0) && !(difference > 2)) {
          // around the center
        } else if (i === center) {
          // at the start or end
        } else if (pages - i <= 2 || i <= 1) {
          // everywhere else
        } else {
          continue;
        }
      }
      links.push({ page: i + 1 });
    }
    return links;
  },
  clone(source) {
    let result = null;
    let type = typeof source;

    if (type !== "boolean" && !source && type !== "number") {
      return null;
    }
    if (type === "object" && Array.isArray(source)) {
      type = "array";
    }
  },
  copy(source, target, except = []) {
    let sourceType = typeof source;
    if (sourceType === "object" && Array.isArray(source)) {
      sourceType = "array";
    }

    let targetType = typeof target;
    if (targetType === "object" && Array.isArray(target)) {
      targetType = "array";
    }

    if (sourceType !== targetType) {
      return;
    }

    switch (sourceType) {
      case "object":
        const members = Object.keys(source);
        for (let i = 0; i < members.length; i++) {
          const member = members[i];
          if (except && except.length) {
            if (!except.includes(member)) {
              target[member] = this.clone(source[member]);
            }
          } else {
            target[member] = this.clone(source[member]);
          }
        }
        break;
      case "array":
        for (let i = 0; i < source.length; i++) {
          const item = source[i];
          target.push(this.clone(item));
        }
        break;
      default:
        return source;
    }
  },

  addOrRemove(arr, val) {
    const index = arr.findIndex((A) => _.isEqual(A, val));
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(val);
    }
    return arr;
  },
  hideTextWithAsterisks(text, visibleChars = 4) {
    if (text.length <= visibleChars) {
      return text;
    }

    const startVisible = text.slice(0, visibleChars);
    const middleHidden = '*'.repeat(Math.max(0, text.length - visibleChars * 2));
    const endVisible = text.slice(-visibleChars);

    return startVisible + middleHidden + endVisible;
  }

};
