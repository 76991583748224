import Vue from "vue";
import App from "./App.vue";
import Game from "./plugins";
import router from "./router";
import store from "./store";
import vSelect from "vue-select";
import VueSweetalert2 from "vue-sweetalert2";
import "vue-select/dist/vue-select.css";
import "sweetalert2/dist/sweetalert2.min.css";
import VueI18n from 'vue-i18n'
import FlagIcon from 'vue-flag-icon'

Vue.use(FlagIcon);
Vue.use(VueI18n)
Vue.use(VueSweetalert2);
Vue.component("v-select", vSelect);


const messages = {
  tr: require('./locales/tr.json'),
  en: require('./locales/en.json'),
};

const i18n = new VueI18n({
  locale:  "en",
  messages,
});

Vue.config.productionTip = false;

Vue.use(Game);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
