import { apiService } from "../../services/api";

const state = {
  accessToken: null,
  refreshToken: null,
};

const mutations = {
  setToken: (state, payload) => {
    if (payload?.data?.Token) {
      localStorage.setItem(
        "accessToken",
        `${payload?.data?.Token}|_|_|${payload?.data?.AccountID}`
      );
      if (payload?.data?.AccountName) {
        localStorage.setItem("nickName", `${payload?.data?.AccountName}`);
      }
    }
    state.accessToken = payload?.data?.Token || null;
  },
};

const actions = {
  async login({ commit }, payload) {
    apiService.post(
      "login",
      { ...payload.form },
      {},
      (response) => {
        commit("setToken", response);
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async newCacheClear({ commit }, payload) {
    apiService.post(
      "frontend/news-cache-clear",
      {},
      {},
      (response) => {
        commit("setToken", response);
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async logOut({ commit }) {
    localStorage.removeItem("accessToken");
    commit("setToken", null);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
