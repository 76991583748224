const version = {
    major: 1,
    minor: 1,
    fix: 5,
  };
  
  const __APP_TITLE__ = "InfinityNest - Manager";
  const __APP_DESCRIPTION__ = "...";
  const __APP_VERSION__ = `${version.major}.${version.minor}.${version.fix}`;
  const __APP_DATE__ = "15.11.2023";
  const __APP_COPYRIGHT__ = "Devility © 2023";
  const __APP_VENDOR_URL__ = "https://devility.com/";
  const __APP_VENDOR_URL_TITLE__ = "bombomoyna.com";
  
  const __BASE_URL__ = "/";
  
  export default {
    APP_TITLE: __APP_TITLE__,
    APP_DESCRIPTION: __APP_DESCRIPTION__,
    APP_VERSION: __APP_VERSION__,
    APP_DATE: __APP_DATE__,
    APP_COPYRIGHT: __APP_COPYRIGHT__,
    APP_VENDOR_URL: __APP_VENDOR_URL__,
    APP_VENDOR_URL_TITLE: __APP_VENDOR_URL_TITLE__,
  
    BASE_URL: __BASE_URL__,
  };
  