import getRoute from "../../plugins/globalRoutes";

export default {
  path: "cashAndPetal",
  meta: {},
  components: {
    home: () => import("../../views/Home/views/CashAndPetal/Index"),
  },
  children: [
    {
      path: "",
      redirect: getRoute("cashAndPetal"),
    },
    {
      name: "cash-and-petal/top-list",
      path: getRoute("cashAndPetal"),
      meta: {},
      components: {
        user: () => import("../../views/Home/views/CashAndPetal/Index"),
      },
    },
  ],
};
