import { apiService } from "../../services/api";

const state = {};

const mutations = {};

const actions = {
  async getUsers({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "users?page=" + payload.page + "&filter=" + payload.filter,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getCashAndPetal({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "users/cash-and-petal-top-list?page=" + payload.page + "&filter=" + payload.filter,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getUserMultiAccount({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      `users/check-multi-account/${payload.CharacterID}/${payload.type}`,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getUserCashIncome({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.get(
      "users/cash-income/" + payload.accountId + "?page=" + payload.page,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async changeUserPassword({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "users/change-password/" + payload.username,
      {},
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async getUserBanList({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "users/restraints",
      payload.data,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async sendUserCoin({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "users/send-cash",
      payload.data,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async deleteRestriction({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "users/delete-restriction/" + payload.id,
      {},
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async accountRestriction({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "users/account-restriction",
      payload.data,
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async searchUserPaginate({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "users/paginate/search",
      {
        name: payload.query,
      },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
  async searchUsers({ commit }, payload) {
    const token = localStorage.getItem("accessToken") ?? null;
    apiService.post(
      "users/search",
      {
        name: payload.query,
      },
      { headers: { Authorization: `Bearer ${token}` } },
      (response) => {
        if (payload && payload.onSuccess) payload.onSuccess(response);
      },
      (error) => {
        if (payload && payload.onError) payload.onError(error);
      },
      () => {
        if (payload && payload.onFinish) payload.onFinish();
      }
    );
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
