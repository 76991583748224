import Vue from "vue";
import Vuex from "vuex";

// begin::Modules
import auth from "./modules/auth";
import users from "./modules/users"
import stats from "./modules/stats"
import portal from "./modules/portal"
import paymentEvent from "./modules/paymentEvent"
import launcherSetting from "./modules/launcherSetting"



// end::Modules

Vue.use(Vuex);  

export default new Vuex.Store({
  modules: {
    auth,
    users,
    stats,
    portal,
    paymentEvent,
    launcherSetting
  },
});
