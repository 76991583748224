import Vue from "vue";
import VueRouter from "vue-router";
import constant from "../plugins/globalVariables";
import globalRoutes from "../plugins/globalRoutes";
import players from "./routes/players";
import multiAccount from "./routes/multiAccount";
import paymentEvent from "./routes/paymentEvent";
import cashAndPetal from "./routes/cashAndPetal";
import launcherSetting from "./routes/launcherSetting";


Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: globalRoutes("notFound"),
  },
  {
    name: "not-found",
    path: globalRoutes("notFound"),
    component: () => import("../views/NotFound/Index"),
  },
  {
    name: "login",
    path: globalRoutes("login"),
    component: () => import("../views/Login/Index"),
  },
  {
    path: "",
    component: () => import("../views/Home/Index"),
    children: [
      {
        name: "home",
        path: globalRoutes("home"),
        components: {
          home: () => import("../views/Home/views/Dashboard/Index"),
        },
      },
      players,
      multiAccount,
      paymentEvent,
      launcherSetting,
      cashAndPetal
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: constant.BASE_URL,
  routes: routes,
});

const documentTitle = `${constant.APP_TITLE} | v${constant.APP_VERSION} (${constant.APP_DATE})`;
const authRoutes = ["login", "forgot-password"];

router.beforeEach((to, from, next) => {
  // Set Page Title
  document.title = to.meta.title || documentTitle;

  if (localStorage.getItem("accessToken")) {
    if (to.name == "login") {
      router.replace("/").catch(() => {});
    } else {
      next();
    }
  } else {
    if (!authRoutes.includes(to.name)) {
      router.replace({ name: "login" }).catch(() => {});
    } else {
      next();
    }
  }
});

export default router;
